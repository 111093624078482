
import Vue, { PropType } from "vue";

import { Notice } from "@/interfaces";

import MyPreview from "@/components/MyPreview.vue";

import { mdiContentSave } from "@mdi/js";

export default Vue.extend({
  components: {
    MyPreview
  },
  props: {
    notice: {
      type: Object as PropType<Notice>,
      required: true
    }
  },
  data() {
    return {
      title: "",
      content: "",
      status: "publish",
      valid: false,
      is_notify: true,
      menu: false
    };
  },
  computed: {
    icon() {
      return { mdiContentSave };
    },
    rules() {
      return {
        title: [(v: string) => !!v || "タイトルは必須です"],
        content: [(v: string) => !!v || "コンテンツは必須です"]
      };
    },
    items() {
      return [
        {
          text: "下書き",
          value: "draft"
        },
        {
          text: "公開",
          value: "publish"
        },
        {
          text: "非公開",
          value: "private"
        }
      ];
    }
  },
  mounted() {
    this.title = this.notice.title;
    this.content = this.notice.content;
    this.status = this.notice.status;
    this.is_notify = this.notice.is_notify;
  },
  methods: {
    submit() {
      this.$emit("save", {
        title: this.title,
        content: this.content,
        status: this.status,
        is_notify: this.is_notify
      });
    }
  }
});
