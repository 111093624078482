
import Vue from "vue";

import http from "@/http";

import MyEditor from "@/components/notice/MyEditor.vue";

import { Notice, Attachment, Breadcrumb } from "@/interfaces";

import { mdiDelete, mdiAttachment } from "@mdi/js";

export interface DataType {
  notice?: Notice;
  attachments: File[];
}

export default Vue.extend({
  components: {
    MyEditor
  },
  data(): DataType {
    return {
      notice: undefined,
      attachments: []
    };
  },
  computed: {
    icon() {
      return { mdiDelete, mdiAttachment };
    },
    breadcrumbs(): Breadcrumb[] {
      if (this.notice == null) {
        return [];
      }

      return [
        {
          text: "ホーム",
          to: "/"
        },
        {
          text: "お知らせ",
          to: "/notices",
          exact: true
        },
        {
          text: this.notice.title
        }
      ];
    }
  },
  async mounted() {
    const { id } = this.$route.params;

    const url = `notices/${id}`;

    try {
      const { data } = await http.get<Notice>(url);

      this.notice = data;
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  },
  methods: {
    async save(i: Notice) {
      if (this.notice == null) {
        return;
      }

      const { id } = this.notice;

      const url = `notices/${id}`;

      const { data } = await http.put<Notice>(url, {
        ...this.notice,
        ...i
      });

      if (this.attachments.length > 0) {
        const url = `notices/${id}/attachments`;

        for (const attachment of this.attachments) {
          const formData = new FormData();

          formData.append("file", attachment);

          await http.post<Notice>(url, formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
        }
      }

      await this.$router.go(-1);
    },

    async close(attachment: Attachment) {
      if (this.notice == null) {
        return;
      }

      const { id } = attachment;

      const url = `attachments/${id}`;

      await http.delete(url, {});

      this.notice.attachments = this.notice.attachments.filter(a => {
        return a.id !== id;
      });
    },

    async click() {
      if (this.notice == null) {
        return;
      }
      const url = `/notices/${this.notice.id}`;

      await http.delete(url);

      this.$router.push("/notice");
    }
  }
});
